import { createWebHashHistory, createRouter } from 'vue-router';

const routes = [
	{
		path: '/',
		component: () => import('@/pages/Console.vue'),
		children: [
			{
				path: '/',
				component: () => import('@/pages/Home.vue'),
			},
			{
				path: '/state/:state',
				component: () => import('@/pages/State.vue'),
			},
			{
				path: '/company/:company',
				component: () => import('@/pages/Company.vue'),
			},
			{
				path: '/offender/:id',
				component: () => import('@/pages/Offender.vue'),
			},
			{
				path: '/report',
				component: () => import('@/pages/Report.vue'),
			},
		],
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;

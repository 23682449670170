<template>
	<router-view />
</template>
<script>
export default {
	name: 'IDF Offender',
	data() {
		return {};
	},
	async created() {},
	methods: {},
};
</script>
<style></style>

import { createApp } from 'vue';
import appVue from './App.vue';
import router from './router';

import 'boxicons/css/boxicons.css';
import 'bulma/css/bulma.css';
import 'leaflet/dist/leaflet.css';
import './assets/css/app.css'; // make this last

let app = createApp(appVue);
app.use(router);

app.mount('#app');

window.comps = [];
